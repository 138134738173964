/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "@fontsource/source-sans-pro"
import "@fontsource/rubik"
import Header from "./header"
import Footer from "./footer"
//import "./layout.css"

const LoginLayout = ({ children, headerType }) => {
  const data = useStaticQuery(graphql`
    query SiteTitle2Query {
      site {
        siteMetadata {
          title
        }
      }
      logoHeader: file(
        relativePath: { eq: "masterclass-logo-white-no-square.svg" }
      ) {
        publicURL
      }
    }
  `)

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        maxWidth: "1600px",
        margin: "0 auto",
        background:
          "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
      }}
    >
      {/* <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        headerType={headerType}
      /> */}
      {/* <div
      // style={{
      //   margin: `0 auto`,
      //   maxWidth: 960,
      //   padding: `0 1.0875rem 1.45rem`,
      // }}
      > */}
      <main sx={{ mb: 5 }}>{children}</main>

      {/* <Footer /> */}
      {/* </div> */}
    </div>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoginLayout
