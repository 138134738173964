/** @jsx jsx */
import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import { Flex, jsx, Box } from "theme-ui"

const LoginLogo = () => {
    const data = useStaticQuery(graphql`
    {
    logoHeader: file(
        relativePath: { eq: "masterclass-logo-white-no-square.svg" }
      ) {
        publicURL
      }
    }
    `)
    
    return (
        <Box className="see-me" sx={{margin: '0 auto', textAlign: 'center'}}>
          <h1 sx={{ mt: [0, "15px "], ml: [2, 0] }}>
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <img
                src={data.logoHeader.publicURL}
                alt="Masterclass logo"
                sx={{ width: ["130px", "150px"] }}
              />
            </Link>
          </h1>
        </Box>
    )
}

export default LoginLogo