/** @jsx jsx */
import { Box, Grid, jsx, Flex, Input, Label, Spinner } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useRef, useState, useEffect } from "react"
import {
  useDispatchCurrentUser,
  useCurrentUser,
} from "../components/CurrentUser"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { callApi, callApiNoAuth } from "../utils"
import { navigate, Link } from "gatsby"
import LoginLayout from "../components/loginLayout"
import LoginLogo from "../components/LoginLogo"
import Tooltip from "../components/Tooltip"
import { FaQuestionCircle } from "react-icons/fa"

//yup schema
let schema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().matches(
    /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/,
    {
      message: "Please enter a stronger password",
    }
  ),
})

const SignUpPage = ({ location }) => {
  const emailRef = useRef()
  const passwordRef = useRef()

  const [errorMsg, setErrorMsg] = useState(null)
  const [validationErrorMsg, setValidationErrorMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  //this is to prevent double submits
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatchCurrentUser()
  let currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      navigate("/app")
    }
    // console.log("currentUser", currentUser)
  }, [currentUser])

  const handleSubmit = async e => {
    e.preventDefault()

    //check against validation schema
    schema
      .validate(
        {
          email: emailRef.current.value,
          password: passwordRef.current.value,
        },
        { abortEarly: false }
      )
      .then(async valid => {
        //valid // => true
        // console.log("validation successful")
        setErrorMsg(null)

        try {
          setSubmitted(true)
          const response = await callApiNoAuth("/auth/local/register", "POST", {
            username: emailRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
          })

          if (response.user) {
            setSubmitted(false)
            setSuccessMsg(
              "<p>Congratulations!  You've successfully signed up for MasterclassTRH. You will receive a confirmation email shortly, follow the link to complete your membership profile.</p> <p>Any questions, email <a href='mailto:info@masterclass.org.uk'>info@masterclass.org.uk</a>.</p>"
            )
          } else {
            const message = `An error has occured:${response?.message?.messages}`
            setErrorMsg(message)
            setSubmitted(false)
            //throw new Error(message)
          }
        } catch (err) {
          setErrorMsg(err)
          setSubmitted(false)
          // console.log("err response", err)
        }
      })
      .catch(function (err) {
        // console.log("validation err", err)
        setValidationErrorMsg(err.errors)
        setSubmitted(false)
      })
  }

  return (
    <LoginLayout>
      <div sx={styles}>
        <LoginLogo />

        <Box className="signUpPage">
          <h1>Sign Up</h1>
          {successMsg ? (
            <div
              sx={{ "& p.success": { color: "red" } }}
              dangerouslySetInnerHTML={{ __html: successMsg }}
            />
          ) : (
            <Box as="form" onSubmit={handleSubmit}>
              <fieldset id="password-reset" sx={{ border: "none", py: 3 }}>
                <p>
                  Aged 16 - 30 and interested in theatre? Want FREE
                  opportunities to regularly engage with the best in the
                  industry?
                </p>
                <p>Sign up for MasterclassTRH by using the form below.</p>

                <div className="field">
                  <Label htmlFor="email-address">Email</Label>
                  <Input
                    ref={emailRef}
                    type="text"
                    name="email-address"
                    id="email-address"
                  />
                </div>
                <div className="field">
                  <Label htmlFor="password">
                    Password{" "}
                    <Tooltip
                      content="Passwords should be at least 8 characters long, include uppercase letter, a lowercase letter, a number and a symbol"
                      direction="right"
                    >
                      <FaQuestionCircle />
                    </Tooltip>
                  </Label>
                  <Input
                    ref={passwordRef}
                    type="password"
                    name="password"
                    id="password"
                  />
                </div>
              </fieldset>
              <div className="buttonRow">
                {submitted && <Spinner />}
                <Input type="submit" value="Submit" disabled={submitted} />
              </div>
              <div sx={{ "& p.error": { color: "red" } }}>
                {errorMsg && <p className="error">{errorMsg}</p>}
                {validationErrorMsg &&
                  validationErrorMsg.map(error => {
                    return <p className="error">{error}</p>
                  })}
              </div>
            </Box>
          )}

          <div sx={{ mt: 2 }}>
            <Link to="/login">Back To Login</Link>
          </div>
        </Box>
      </div>
    </LoginLayout>
  )
}

export default SignUpPage

const styles = {
  "& .signUpPage": {
    margin: "0 auto",
    maxWidth: "50%",
    background: "white",
    padding: 4,
    "& p.error": {
      color: "red",
    },
    "& .field": {
      mb: 3,
    },
    "input[type=submit]": {
      bg: "accent",
      color: "white",
      cursor: "pointer",
      transition: "background 0.3s ease-in-out",
      "&:hover:enabled": {
        background: darken("accent", 0.1),
      },
      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.3,
      },
    },
    "input[type=text]:focus-visible": {
      outline: "red",
    },
    a: {
      color: "accent",
    },
    ".otherLinks": {
      margin: "0 auto",
      mt: 3,
      textAlign: "center",
      a: {
        pr: 3,
      },
    },
  },
  "& .error": {
    color: "red",
  },
  "& .Tooltip-Wrapper": {
    ml: 2,
    "&:hover": {
      cursor: "pointer",
    },
    svg: {
      verticalAlign: "-0.16em",
    },
  },
  "& .buttonRow svg": {
    color: "accent",
    textAlign: "center",
    mx: "auto",
    display: "block",
    mb: 2,
  },
}
